import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { config, userRequest } from "../requestMethods";

const Success = () => {
  const location = useLocation();
  const data = location.state.stripeData;
  const cart = location.state.cart;
  const currentUser = useSelector((state) => state.user.currentUser);
  const [orderId, setOrderId] = useState(null);

  useEffect(() => {
    const createOrder = async () => {
      try {
        console.log("congig:",config);
        const res = await userRequest.post("/orders", { 
          userId: currentUser.userId,
            products: cart.products.map((item) => ({
            productId: item.productId,
            quantity: item.quantity,
          })),
          amount: cart.total,
          address: data.billing_details.address,
          status: "Pendente"
        },config);
        console.log("createorder:",res);

        setOrderId(res.data.orderId);
      } catch (e){
        console.log("useEffect:erro:orders",e)
      }
    };
    data && createOrder();
  }, [cart, data, currentUser]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {orderId
        ? `Order has been created successfully. Your order number is ${orderId}`
        : `Sucesso! Seu pedido está sendo preparado..`}
      <button style={{ padding: 10, marginTop: 20 }}>Muito Obrigado</button>
    </div>
  );
};

export default Success;
