import { useEffect, useState } from "react";
import styled from "styled-components";
import Product from "./Product";
import { userRequest } from "../requestMethods";


const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

`;

const Container = styled.div`
background-color: white;
  padding: 20;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;



const Products = ({ cat, filters, sort }) => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    // const getProducts = async () => {
    //   try {
    //     const res = await axios.get(
    //       cat
    //         ? `http://localhost:8080/api/products?category=${cat}`
    //         : "http://localhost:8080/api/products"
    //     );
    //     console.log(res.data)
    //     setProducts(res.data);
    //   } catch (err) {}
    // };
    // getProducts();
    const getProducts = async () => {
      try {
        const res = cat
          ? await userRequest.get(`products?category=${cat}`) 
          : await userRequest.get(`products`);
          setProducts(res.data);
          console.log("products",res.data);
      } catch (err) {}
    };

    getProducts();
  }, [cat]);

  useEffect(() => {
    cat &&
      setFilteredProducts(
        products.filter((item) =>
          Object.entries(filters).every(([key, value]) =>
            item[key].includes(value)
          )
        )
      );
  }, [products, cat, filters]);

  useEffect(() => {
    if (sort === "newest") {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.createdAt - b.createdAt)
      );
    } else if (sort === "asc") {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.price - b.price)
      );
    } else {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => b.price - a.price)
      );
    }
  }, [sort]);
  return (
    <Container>
      {cat
        ? filteredProducts.map((item) => <Product item={item} key={item.productId} />)
        : products
            .slice(0, 8)
            .map((item) => <Product item={item} key={item.productId} />)}
    </Container>
  );
};

export default Products;
