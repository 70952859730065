import { removeItem, resetCart } from "../redux/cartRedux";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useSelector } from "react-redux";
import styled from "styled-components";
import { mobile } from "../responsive";
import StripeCheckout from "react-stripe-checkout";
import { useEffect, useState } from "react";
import { config,userRequest } from "../requestMethods";
import {useNavigate} from 'react-router-dom';
import { useDispatch } from "react-redux";
import Button from '@mui/material/Button';
import { useLocation } from "react-router";
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs'

const KEY = "pk_test_51NH8cXKs8KhYFADCwTNIjdyM0gJYVJ3tVHzM1haJZKugrknfHbCtYmce643sF5IJIghu2gbh7KsdGKMrpr7hGYeC00ujG3Adfe"
const Container = styled.div``;

const Wrapper = styled.div`
  padding: 20px;
  ${mobile({ padding: "10px" })}

`;

const Title = styled.h1`
  font-weight: 200;
  text-align: center;
`;


const Info = styled.div`
  flex: 3;
  background-color: white;
`;
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;

  ${mobile({ flexDirection: "column" })}
`;
const Product = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding:5px;
  ${mobile({ flexDirection: "column" })}
`;

const ProductDetail = styled.div`
  flex: 2;
  display: flex;
  background-color: white;
`;

const Image = styled.img`
  width: 200px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  margin-left:5px;
`;

const ProductName = styled.div`
background-color: white;
width:100%;
`;
const ProductValue = styled.div`
diplay:flex;
background-color: white;
width:100%;
margin-bottom:7px;
`;

const ProductQuantidade = styled.div`
diplay:flex;
background-color: white;
width:100%;
margin-bottom:7px;
`;

const Delete = styled.div`
display:flex;
background-color: white;
width:100%;
margin-top:0;
justify-content: flex-end;
`;

const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 1px;
`;

const ProdCarts = styled.div`
  flex: 1;
  border: 0.5px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  height: 50vh;
`;
const Summary = styled.div`
  flex: 1;
  border: 0.5px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  height: 50vh;
`;

const SummaryTitle = styled.h1`
  font-weight: 200;
  text-align: center;
  margin-bottom:7px;
`;

const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: ${(props) => props.type === "total" && "500"};
  font-size: ${(props) => props.type === "total" && "20px"};
`;

const SummaryPedido = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: blue;
`;
const SummaryItemText = styled.span``;

const SummaryItemPrice = styled.span``;
const AddContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: "100%"
  width: "100%"
`;

const Cart = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const [stripeToken, setStripeToken] = useState(null);
  const history = useNavigate();

  const location = useLocation();
  //const data = location.state.stripeData;
  const currentUser = useSelector((state) => state.user.currentUser);
  const [orderId, setOrderId] = useState(null);

  const onToken = (token) => {
    setStripeToken(token);
  };

    const createOrder = async () => {
      console.log("currentUser",currentUser);
      try {
        const unique_id = uuid();
        const res = await userRequest.post("/orders",{ 
          orderId: dayjs(new Date()).format("YYYYMMDDhhmmss"),
          clientId: currentUser.clientId,
          uniqueId:unique_id,
            products: cart.products.map((item) => ({
            productId: item.productId,
            quantity: item.quantity,
            uniqueId:unique_id,
          })),
          amount: cart.total,
          address: "",
          quantity: cart.quantity,
          status: "Pendente"
        },config);

        setOrderId(res.data.orderId);
      } catch (e){
        console.log("useEffect:erro:orders",e)
      }
    };

  useEffect(() => {
    dispatch(resetCart());
    const  makeRequest = async () => {
      try { 
        console.log("stripeToken",stripeToken)
        const res = await userRequest.post("/checkout/payment", {
          tokenId: stripeToken.id,
          amount: 500,
        });
        history("/success", {state:{
          stripeData: res.data,
          cart: cart, }});
      } catch(e) {
        console.log("useEffect",e)
      }
    };
    stripeToken && makeRequest();
  }, [stripeToken, cart.total, history]);

  return (
    <Container>
      <Wrapper>
        <Title>Carrinho</Title>
        <Bottom>
          <Info>
            
            {cart.products.map((product) => (
              <Product>
                <ProductDetail>
                  <Image src={product.img} />
                  <Details>
                    <ProductName>
                      <b>Produto:</b>
                    </ProductName>
                    <ProductValue>
                     {product.title}
                    </ProductValue>
                    <ProductName>
                      <b>Produto:</b>
                    </ProductName>
                    <ProductValue>
                    {product.desc}
                    </ProductValue>
                    <ProductName>
                      <b>Referencia:</b>
                    </ProductName>
                    <ProductValue>
                    {product.ref}
                    </ProductValue>
                    <ProductQuantidade>
                    <ProductName>
                    <b>Quantidade:</b>{product.quantity}
                  </ProductName>
                  </ProductQuantidade>
                  <ProductName>
                    R${(product.price * product.quantity).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }
                  </ProductName>
                  <Delete>
                  <DeleteOutlineOutlinedIcon
            onClick={() => dispatch(removeItem(product))}/>
            </Delete>
                  </Details>
                </ProductDetail>
              </Product>
            ))}
            <Hr />
          </Info>
          <Summary>
            <SummaryTitle>Pedido</SummaryTitle> 
            <SummaryItem type="total">
              <SummaryItemText>Quantidade Total:</SummaryItemText>
              <SummaryItemPrice>{cart.quantity}</SummaryItemPrice>
            </SummaryItem>
            <SummaryItem type="total">
              <SummaryItemText>Total:</SummaryItemText>
              <SummaryItemPrice>R$ {cart.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }</SummaryItemPrice>
            </SummaryItem>
            <StripeCheckout
              name="Lama Shop"
              image="https://avatars.githubusercontent.com/u/1486366?v=4"
              billingAddress
              shippingAddress
              description={`Your total is $${cart.total}`}
              amount={cart.total * 100}
              token={onToken}
              stripeKey={KEY}
            >
              <Button>CHECKOUT NOW</Button>
            </StripeCheckout>
            <AddContainer>
            <Button variant="contained" onClick={createOrder}>Comprar Agora</Button>
            </AddContainer>
            <AddContainer>
            <Button variant="contained" onClick={() => dispatch(resetCart())}>resetar
            </Button>
            </AddContainer>
          </Summary>
        </Bottom>
      </Wrapper>
    </Container>
  );
};

export default Cart;
