import { createSlice,current } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    products: [],
    quantity: 0,
    total: 0,
  },
  reducers: {


    addProduct: (state, action) => {

      state.products.push(action.payload);
      state.quantity = state.products.reduce((a,v) =>  a = a + v.quantity , 0 )
      state.total =  state.products.reduce((a,v) =>  a = a + v.quantity * v.price, 0 )

    },
    updateProduct: (state, action) => {

      console.log("state1",state.quantity,current(state))
      state.products = [
        ...state.products.filter(item=>item.productId !== action.payload.productId),
        action.payload
      ];

      state.quantity = state.products.reduce((a,v) =>  a = a + v.quantity , 0 )
      state.total =  state.products.reduce((a,v) =>  a = a + v.quantity * v.price, 0 )

      //state.quantity = action.payload.quantity ;
      //state.total = action.payload.price * action.payload.quantity;

    },
    removeItem: (state,action) => {

      if(state.quantity === 0)
      {
        state.quantity=0
      }
      else{

        console.log("state0",state.products,current(state))
        console.log("action.payload.productId",action.payload.productId)
        state.products = [
        ...state.products.filter(item=>item.productId !== action.payload.productId)
        ]
      }
      console.log("state1",state.products,current(state))

      state.quantity = state.products.reduce((a,v) =>  a = a + v.quantity , 0 )
      state.total =  state.products.reduce((a,v) =>  a = a + v.quantity * v.price, 0 )
      

    },
    resetCart: (state) => {
      state.products = []
      state.total = 0;
      state.quantity = 0;
    },
  },
});

export const { addProduct,removeItem,resetCart,updateProduct  } = cartSlice.actions;
export default cartSlice.reducer;
