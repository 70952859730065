import {
  FavoriteBorderOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from '@mui/icons-material';
import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile } from "../responsive";
import { useSelector } from "react-redux";
import { UPLOAD_BASE_URL } from "../requestMethods";

/*
const Container = styled.div`
  flex: 1;
  margin: 2px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: yellow;
  position: relative;
  padding:0;
border: 0;

  &:hover ${Info}{
    opacity: 1;
  }
  ${mobile({ width: "50%" })}
`;

const Image = styled.img`
  height: 100%;
  z-index:2;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  transition: all 0.5s ease;
  &:hover {
    background-color: #e9f5f5;
    transform: scale(1.1);
  }
`;
const Desc = styled.h1`
  font-weight: bold;
`;

const Product = ({ item }) => {
  return (
    <Container>
      <Image src={item.img}/>
      <Info>
        <Icon>
          <Link to={`/product/${item.productId}`}>
          <SearchOutlined />
          </Link>
        </Icon>
      </Info>
    </Container>
  );
};
*/
const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  transition: all 0.5s ease;
  &:hover {
    background-color: #e9f5f5;
    transform: scale(1.1);
  }
`;

const Info = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  cursor: pointer;
`;
const Container = styled.div`
background-color: white;
width: 100%;
padding: 2px;
flex: 1;
display: flex;
flex-direction: row;
`;
const Wrapper = styled.div`
  display: flex;
  background-color: white;
  width: 100%;
  padding: 2px;
  flex-direction: column;
  display: flex;
align-items: center;
  ${mobile({ padding: "0px"})}
  &:hover ${Info}{
    opacity: 1;
  }
`;

const ImgContainer = styled.div`
flex: 1;
`;

const Image = styled.img`
  height: 40vh;
  width: 150;
  object-fit: cover;
`;

const InfoDesc = styled.div`
background-color: white;
width: 100%;
height: 30px;
align-items: center;
justify-content: center;
display:flex;
font-size: 13px;
`;
const InfoPrice = styled.div`
  background-color: white;
  width: 100%;
  height: 30px;
  align-items: center;
  justify-content: center;
  display:flex;
  font-weight: bold;
`;

const Price = styled.span`
  font-size: 20px;
`;


const Product = ({ item }) => {
  console.log('*********',item);
const currentUser = useSelector((state) => state.user.currentUser);


return (
  <Container>
    <Wrapper>
      <ImgContainer>
          <Link to={`/product/${item.productId}`}>
          <Image src={`${UPLOAD_BASE_URL}/${item.img}`}/>
          </Link>
      </ImgContainer>
      <InfoDesc>
        {item.title}
        </InfoDesc>
        <InfoPrice>
        { ! currentUser ? <Link to="/login" style={{ textDecoration: 'none' }}>Veja o preço</Link> :  <Price>R$ {item.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Price>}
      </InfoPrice>
    </Wrapper>
  </Container>
);
};
export default Product;
//<Image src={item.img}/>

