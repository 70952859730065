import styled from "styled-components";
import { mobile } from "../responsive";
import { useState } from "react";
import { userRequest } from "../requestMethods";
import Swal from 'sweetalert2';
import {useNavigate} from 'react-router-dom';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 40%;
  padding: 20px;
  background-color: white;
  ${mobile({ width: "75%" })}
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 20px 10px 0px 0px;
  padding: 10px;
`;

const Agreement = styled.span`
  font-size: 12px;
  margin: 20px 0px;
`;

const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 20px;
  background-color: teal;
  color: white;
  cursor: pointer;
`;



const Register = () => {

  const [inputs, setInputs] = useState({});
  const nav = useNavigate();

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]:e.target.value };
    });
  };
  const updateClick = async  (event) => {
    event.preventDefault();
  
      InserirUser();
  }
  const InserirUser = async () =>{
    try {
      console.log(inputs )
      console.log(inputs.password )
      console.log(inputs.confirm_password)
      if(inputs.password === inputs.confirm_password)
      {

      const res = await userRequest.post("/auth/registerClient",
      { 
        name: inputs.name,
        cnpj_cpf: inputs.cnpj_cpf,
        rzSocial: inputs.rzSocial,
        email:inputs.email,
        password: inputs.password,
        contact: inputs.contact,
      }).then((response) => {

        nav("/home")
        Swal.fire(
          'Parabéns!',
          'Bem-Vindo a nossa Loja!',
          'success'
        )  
      }).catch((err) => {
        Swal.fire(
          'Atenção!',
          'Erro ao registra-se, favor entrar em contato com uma de nossas vendedoras!',
          'error'
          )
      });
    }
    else{
      Swal.fire(
        'Atenção!',
        'Favor verificar sua senha!',
        'error'
        )
    }
    }
    catch (error)
    {
      console.log();
    }   
  }


  return (
    <Container>
      <Wrapper>
        <Title>BEM-VINDO A NOSSA LOJA!</Title>
        <Form>
          <Input name="name" onChange={handleChange} placeholder="nome fantansia" />
          <Input name="cnpj_cpf" onChange={handleChange}  placeholder="Cnpj ou Cpf"/>
          <Input name="contact" onChange={handleChange}  placeholder="Celular"/>
          <Input name="rzSocial" onChange={handleChange}  placeholder="razão social"/>
          <Input name="email" onChange={handleChange}  placeholder="email"/>
          <Input name="password" onChange={handleChange}  placeholder="senha"/>
          <Input name="confirm_password" onChange={handleChange} placeholder="confirma senha" />
          <Agreement>
            Ao cadastrar-se, você aceita nossa <b>políca de privacidade</b>
          </Agreement>
          <Button onClick={updateClick}>CADASTRAR</Button>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default Register;
