import { loginFailure, loginEnd, loginStart, loginSuccess } from "./userRedux";
import { publicRequest } from "../requestMethods";


export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/loginClient", user);
    dispatch(loginSuccess(res.data));
  } catch (err) {
    dispatch(loginFailure());
  }
};
export const logOut= async (dispatch) => {
  try {
    dispatch(loginEnd());
  } catch (err) {
  }
};