import React from 'react';
import styled from 'styled-components';
import Burger from './Burger';
import { mobile } from "../responsive";

const Image = styled.img`
  width: 50%;
  height:50%;
  object-fit: cover;
  margin-left: 25px;
  ${mobile({width:"30%",height:"30%",marginLeft: "10px"})}
`;
const Nav = styled.nav`
  width: 100%;
  height: 55px;
  border-bottom: 2px solid #f1f1f1;
  padding: 0px
  display: flex;
  justify-content: space-between;

  .logo {
    padding: 15px 0;
  }
`

const Navbar = () => {
  return (
    <Nav>
      <div className="logo">
      <Image src="https://d3ugyf2ht6aenh.cloudfront.net/stores/001/628/532/themes/common/logo-1802997584-1617298767-cff10baddaf78aa2d490bfa2c0fe34f41617298767-480-0.png?0" />
      </div>
      <Burger />
    </Nav>
  )
}

export default Navbar
