import { Children } from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Product from "./pages/Product";
import ProductList from "./pages/ProductList";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Cart from "./pages/Cart";
import Success from "./pages/Success";
import { useSelector } from "react-redux";

const Layout = () => {

  return (
    <div>
      <Navbar/>
      <Outlet />
      <Footer />
    </div>
  );
};


const router = createBrowserRouter([
  {
    
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "products/:category",
        element: <ProductList/>,
      },
      {
        path: "product/:id",
        element: <Product/>,
      },
      {
        path: "cart",
        element: <Cart/>,
      },
      {
        path: "login",
        //{user ? <Redirect to="/" /> : <Login />}
        element:<Login />,
      },
      {
        path: "register",
        //{user ? <Redirect to="/" /> : <Register />}
        element: <Register/>,
      },
      {
        path: "success",
        element: <Success/>,
      }
    ],
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
