import { Add, Remove } from '@mui/icons-material';
import styled from "styled-components";
import { mobile } from "../responsive";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { publicRequest, userRequest } from "../requestMethods";
import { addProduct,resetCart,updateProduct } from "../redux/cartRedux";
import { useDispatch } from "react-redux";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel} from "react-responsive-carousel";

const Container = styled.div``;

const Wrapper = styled.div`
  display: flex;
  padding: "10px";
  align-items: center;
  margin: 10px 25px 10px 25px;
  id: warpper;
  ${mobile({ flexDirection: "column" })}
`;

const ImgContainer = styled.div`
  background-color: white;
  align-items: center;
  background-color: white;
  id: ImgContainer;
`;

const Image = styled.img`
display: flex;
  height:70vh;
  object-fit: cover;
  align-items: center;
`;

const InfoContainer = styled.div`
  flex: 1;
  background-color: white;
  id: InfoContainer;
  ${mobile({flexDirection: "column" })}
`;

const Title = styled.div`
  font-weight: 200;
  font-size: 18px;
  margin: 10px 25px 10px 10px; 
  id: Title;
`;
const VejaPreco = styled.div`
  font-size: 16;
  font-color:blue;
  margin-left: 10px; 
  justify-content: flex-end;
  id: VejaPreco;
`;

const Desc = styled.div`
font-weight: 200;
font-size: 18px;
  margin: 10px 25px 10px 10px; 
  id: Desc;
`;

const Price = styled.div`
font-weight: bold;
font-size: 20px;
  padding: "10px";
  margin: 10px 25px 10px 10px; 
  justify-content: flex-end;
  id: Price;

`;

const AddContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  id: AddContainer;

  ${mobile({ width: "100%" })}
`;
const AddContainerComprar = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  margin:2px;
  justify-content: center;
  ${mobile({ width: "100%" })}
`;
const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid black;
  margin:2px;
  height:140px;
  width:55px;
`;
const Amount = styled.div`
display flex;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  border-style: dashed;
  border: 1px solid teal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
  flexDirection: "column"
  background-color: white;
`;

const Product = () => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [product, setProduct] = useState({});
  const dispatch = useDispatch();
  const [productImgs, setProductImgs] = useState([{}]);
  const [quantity, setQuantity] = useState(1);
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const cart = useSelector((state) => state.cart);


  useEffect(() => {
    const getProduct = async () => {
      try {
        const res = await userRequest.get("/products/find/" + id);
        

        res.data.map((item)=>
        setProduct(item)
        );

      } catch {}
    };
    const getProductImgs = async () => {
      try {
        const res = await userRequest.get("/imgs/find/" + id);
        setProductImgs(res.data);
      } catch {}
    };
    getProduct();
    getProductImgs();

  }, [id]);

  const handleQuantity = (type) => {
    if (type === "dec") {
      quantity > 1 && setQuantity(quantity - 1);
    } else {

      setQuantity(quantity + 1);
    }

  };

  const Loginlick = () => {
    dispatch(
      addProduct({ ...product, quantity, color, size })
    );
  };
  const handleClick = () => {


   const item=cart.products.find((item)=>item.productId === product.productId)
    if(item) 
    {
      setProduct(item);
      product.quantity = item.quantity + quantity;
      console.log("productUpdate",product);

      dispatch(
        updateProduct(product));
    }
    else {

      dispatch(
        addProduct({ ...product,quantity}));
      
    }
      console.log("product",product);

  };
  const currentUser = useSelector((state) => state.user.currentUser);
  return (
    <Container>
      <Wrapper>
        <ImgContainer>
        <Carousel infiniteLoop >
          {productImgs.map((item) => (
          <Image src={item.img} />
          ))}
          </Carousel>
        </ImgContainer>
        <InfoContainer>
          { ! currentUser ? <Price><Link to="/login" style={{ textDecoration: 'none' }}>Veja o preço</Link> </Price>:  <Price>R$:{product.price}</Price>}
          <Title>{product.title}</Title>
          <Desc>{product.desc}</Desc>
          <AddContainer>
            <AmountContainer>
            <FormControlLabel
          value="top"
          control={<Checkbox />}
          label="Top"
          labelPlacement="top"
        />
              <Remove align="center" onClick={() => handleQuantity("dec")} />
              <Amount>{quantity}</Amount>
              <Add onClick={() => handleQuantity("inc")} />
            </AmountContainer>
            <AmountContainer>
              <FormControlLabel
              value="top"
              control={<Checkbox />}
              label="Top"
              labelPlacement="top"
              />
              <Remove align="center" onClick={() => handleQuantity("dec")} />
              <Amount>{quantity}</Amount>
              <Add onClick={() => handleQuantity("inc")} />
            </AmountContainer>
            <AmountContainer>
              <FormControlLabel
              value="top"
              control={<Checkbox />}
              label="Top"
              labelPlacement="top"
              />
              <Remove align="center" onClick={() => handleQuantity("dec")} />
              <Amount>{quantity}</Amount>
              <Add onClick={() => handleQuantity("inc")} />
            </AmountContainer>
            <AmountContainer>
              <FormControlLabel
              value="top"
              control={<Checkbox />}
              label="Top"
              labelPlacement="top"
              />
              <Remove align="center" onClick={() => handleQuantity("dec")} />
              <Amount>{quantity}</Amount>
              <Add onClick={() => handleQuantity("inc")} />
            </AmountContainer>
            <AmountContainer>
              <FormControlLabel
              value="top"
              control={<Checkbox />}
              label="Top"
              labelPlacement="top"
              />
              <Remove align="center" onClick={() => handleQuantity("dec")} />
              <Amount>{quantity}</Amount>
              <Add onClick={() => handleQuantity("inc")} />
            </AmountContainer>
            <AmountContainer>
              <FormControlLabel
              value="top"
              control={<Checkbox />}
              label="Top"
              labelPlacement="top"
              />
              <Remove align="center" onClick={() => handleQuantity("dec")} />
              <Amount>{quantity}</Amount>
              <Add onClick={() => handleQuantity("inc")} />
            </AmountContainer>
            </AddContainer>
            <AddContainerComprar>
            <Button variant="contained" onClick={handleClick}>Comprar Agora</Button>
            </AddContainerComprar>
        </InfoContainer>
      </Wrapper>
    </Container>
  );
};
  /*
  return (
    <Container>
      <Wrapper>
        <ImgContainer>
          <Image src={product.img} />
        </ImgContainer>
        <InfoContainer>
          <Title>{product.title}</Title>
          <Desc>{product.desc}</Desc>
          <Price>R$ {product.price}</Price>
          <AddContainer>
            <AmountContainer>
              <Checkbox>Teste</Checkbox>
              <Remove onClick={() => handleQuantity("dec")} />
              <Amount>{quantity}</Amount>
              <Add onClick={() => handleQuantity("inc")} />
            </AmountContainer>
            <AmountContainer>
              <Checkbox>Teste</Checkbox>
              <Remove onClick={() => handleQuantity("dec")} />
              <Amount>{quantity}</Amount>
              <Add onClick={() => handleQuantity("inc")} />
            </AmountContainer>
            <AmountContainer>
              <Checkbox>Teste</Checkbox>
              <Remove onClick={() => handleQuantity("dec")} />
              <Amount>{quantity}</Amount>
              <Add onClick={() => handleQuantity("inc")} />
            </AmountContainer>
            </AddContainer>
        </InfoContainer>
      </Wrapper>
    </Container>
  );
};
*/
export default Product;


