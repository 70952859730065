import styled from "styled-components";
import Products from "../components/Products";
import { mobile } from "../responsive";
import { useLocation } from "react-router";
import { useState } from "react";
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { categories } from "../data";
import InputBase from '@mui/material/InputBase';

const Container = styled.div`
background-color: white;
height:100%
`;

const Title = styled.h1`
  margin: 20px;
  background-color: orange;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  marginTop:25px;
  background-color: white;
  height:60px;
  align-items: center;
  margin-left: 25px;
  margin-right: 25px;
`;

const Filter = styled.div`
flex:1
  background-color: white;
  height:50%
  width: 300
  margin: 20px;
  margin-top:20px
  ${mobile({width: "100"})}
`;

/*
const Select = styled.select`
  padding: 10px;
  margin-right: 20px;
  ${mobile({ margin: "10px 0px" })}
`;
*/
const Option = styled.option``;


const ProductList = () => {
  const location = useLocation();
  const cat = location.pathname.split("/")[2];
  const [filters, setFilters] = useState([]);
  const [personName, setPersonName] = React.useState([]);
  const [sort, setSort] = useState("asc");
  const theme = useTheme();

  const handleFilters = (e) => {
    const value = e.target.value;
   setFilters({
      ...filters,
      [e.target.name]: value,
    });
  };
 
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const MenuProps = {
    PaperProps: {
        sx: {
            '& .MuiMenuItem-root': {
                fontSize: 10,
            },
        },
        style: {
            fontSize: 10,
        },
    },
};
  return (
    <Container>
            <FilterContainer>
        <FormControl sx={{ m:0, minWidth: 200 }} size="small">
        <InputLabel id="filtersize">Filtros</InputLabel>
        <Select st
          MenuProps={MenuProps}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        >
          {categories.map((item) => (
            <MenuItem 
              key={item.title}
              value={item.title}
            >
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 0, minWidth: 120 }} size="small">
      <InputLabel >Ordenar</InputLabel>
      <Select
        onChange={(e) => setSort(e.target.value)}
      >
        <MenuItem value="">
          <em></em>
        </MenuItem>
        <MenuItem value="newest">Lançamentos</MenuItem>
        <MenuItem value="asc">Menor Preço</MenuItem>
        <MenuItem value="desc">Maior Preço</MenuItem>
      </Select>
    </FormControl>
      </FilterContainer>
      {console.log(personName)}
      <Products cat={cat} filters={filters} sort={sort} />
      </Container>
  );
};

export default ProductList;
