import axios from "axios";

export const UPLOAD_BASE_URL = process.env.NODE_ENV === 'production' 
                                  ? "https://ecommerce-api.inchargeof.com/public/upload/"
                                  : "http://localhost:8080/public/upload/";

export const BASE_URL = process.env.NODE_ENV === 'production' 
                          ? "https://ecommerce-api.inchargeof.com/api/"
                          : "http://localhost:8080/api/";

const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
const currentUser = user && JSON.parse(user).currentUser;
const TOKEN = currentUser?.accessToken;


//const TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjIsImlzQWRtaW4iOnRydWUsImlhdCI6MTY4NjIyOTc2NiwiZXhwIjoxNjg2NDg4OTY2fQ.stlcRCF6F58QYS6Ri1ta3utUPwhQTX4L4z3mdBEy_Ug"
export const publicRequest = axios.create({
  baseURL: BASE_URL,
});
export const config = {
  headers: { token: `Bearer ${TOKEN}` }
}

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: { token: `Bearer ${TOKEN}` },
},);
